.App {
  text-align: center;
  font-family: Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.blueUnimiBG {
  background-color: #02336A;
}

.blueUnimiColor {
  color: #02336A;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.customLabel {
  font-weight: bold;
  color: #02336A;
}

.tableCustomStyle .rdt_TableHeadRow {
  background-color: #02336A;
}

.tableCustomHeader .rdt_TableHeadRow {
  display: none;
}

.rdt_TableCol {
  color: white!important;
}

.curosorHover:hover{
  cursor: pointer;
}

.listContainer {
  margin-left: 20%;
  margin-right: 20%;
  overflow-y: auto;
  max-height: 300px;
  margin-bottom: 2px;
}

.ricercaAvanzata {
  color: #007bff;
}

.ricercaAvanzata:hover {
  cursor: pointer;
}

.datepicker {
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.react-datepicker-wrapper, .react-datepicker__input-container{
  width: 100%;
}

.datepicker:focus{
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) 
}

.labelRangeCalendar {
  font-size: 12px;
}

.alertConflitti{
  padding-left: 30%;
  padding-right: 30%;
}

.modal-header .close {
  color: white;
}

.content-block-title{
  border-bottom: 1px solid #777777;
}

.autenticazioneTitle {
  font-size: 24px;
  font-family: Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.buttonLogin {
  color: #fff;
  background-color: #174372;
  border-color: #13375d;
  width: 100%;
  padding: 10px 16px;
  font-size: 18px;
}

.buttonLogin:hover {
  background-color: #0e2a48!important;
  border-color: #08192a!important;
}

.loader{
  text-align: center;
  padding: 10px;
}
.modifyCustomButton:hover {
  text-decoration: underline;
  cursor: pointer;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* The container */
.containerInputbox {
  /* display: block; */
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* When the radio button is disabled, add cursor not allowed */
.containerInputbox:hover input:disabled ~ .checkmark{
  cursor: not-allowed;
  background-color: #eee;
}

/* When the radio button is disabled, reset background-color */
.containerInputbox input:checked:disabled ~ .checkmark {
  background-color: #eee;
}

/* Hide the indicator (dot/circle) when disabled */
.containerInputbox input:checked:disabled ~ .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


/* Hide the browser's default radio button */
.containerInputbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 10%;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containerInputbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerInputbox input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerInputbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerInputbox .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.logoCompetenze {
  position: absolute;
  bottom: -15px;
  color: blue;
  font-weight: bold;
  font-family: calibri;
  font-size: 18px;
}

.stickyNotes {
  color: #F5D027;
}

.stickyNotesBackground {
  background-color: #F5D027;
}

.stickyNotes:hover {
  cursor: pointer;
}

.circularImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #abd2ff;
  float: left;
  border: #02336A solid 2px;
  
}

.circularImage:hover { 
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
